@use "../../scss/" as *;

.c-launchtype {
    border-radius: 10px;

    .icon {
        color: white !important;
        font-size: 25px;
        font-weight: 900;
        display: flex;
        text-transform: capitalize;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        height: auto;
        position: relative;

        div {
            margin-right: 15px;
            color: white;

            &.success {
                color: $ourColor;
            }
        }

        .c-info {
            position: absolute;
            right: -10px;
            top: -20px;
            color: #7e7e7e;
        }
    }



    .c-launchtype-btn {
        text-align: center;
        padding: 10px;
        margin: 0px !important;
        font-size: 18px;
        cursor: pointer;
        transition: ease-in-out all 0.3s;
        color: white;
        border-radius: 30px;
        border: 1px solid white;
        font-weight: 700;

        &:hover {
            box-shadow: 0px 0px 15px white;
        }

        &.success {
            color: $ourColor;
            border-color: $ourColor;
            // text-shadow: 1px 1px 1px white, -1px 1px 1px white, -1px -1px 1px white, 1px -1px 1px white;
        }
    }
}