@use "../../scss" as *;

/* box
-------------------------------------------------------------- */
.project-box {
  position: relative;

  .image {
    position: relative;
    height: 112px;
    width: 112px;
    margin: 0 auto;
    z-index: 99;
    background: url("./../../assets/images/common/project_1.png") center center no-repeat;

    img {
      &.mask {
        width: 112px;
        height: 112px;
        -webkit-mask-image: url("./../../assets/images/common/project_1.png");
        mask-image: url("./../../assets/images/common/project_1.png");
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        text-align: center;
        overflow: hidden;
      }
    }

    .shape {
      position: absolute;
      right: 2px;
      top: 2px;
      z-index: -1;
      width: 30px;
      height: 30px;
    }
  }

  .content {
    overflow: hidden;
    position: relative;
    margin-top: -56px;
    text-align: center;
    padding: 80px 20px 42px 16px;
    background: #1e2835;
    border-radius: 20px;

    .social {
      li {
        margin-bottom: 0;
      }
    }

    .boder {
      border-radius: 20px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: 2px solid;
      z-index: 9;
      border-image-slice: 1;
      border-image-source: linear-gradient(to bottom,
          rgba(255, 255, 255, 0.0784313725),
          rgba(255, 255, 255, 0));
    }

    .content-inner {
      z-index: 10;
      position: relative;

      .heading {
        margin-bottom: 5px;
      }
    }

    &::after {
      width: 50px;
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      border: solid 1.7px rgba(255, 255, 255, 0.0784313725);
      border-color: rgba(255, 255, 255, 0.0784313725) transparent transparent transparent;
      border-radius: 50%/10px 10px 0 0;
      transform: rotate(40deg);
      top: 2px;
      right: 2px;
    }

    &::before {
      width: 50px;
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      border: solid 1.5px rgba(255, 255, 255, 0.0784313725);
      border-color: rgba(255, 255, 255, 0.0784313725) transparent transparent transparent;
      border-radius: 50%/10px 10px 0 0;
      transform: rotate(-40deg);
      left: 2px;
      top: 2px;
    }

    .title {
      margin-bottom: 6px;
    }

    .desc {
      margin-bottom: 22px;
      padding: 0 20px;
    }

    ul {
      margin-bottom: 22px;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        @include flex(flex-start, space-between);

        .text {
          @include flex(center, flex-start);
          padding: 8px 12px 8px 16px;
          height: 40px;
          width: 100%;
          max-width: 230px;
          background: url("./../../assets/images/common/bg_text.png");
          background-position: center right;
          background-repeat: no-repeat;
          margin-bottom: 0;
          font-size: 17px;
        }

        .price {
          @include flex(center, flex-end);
          padding: 8px 10px;
          height: 40px;
          width: 90px;
          background: url("./../../assets/images/common/bg_text2.png");
          background-position: center left;
          background-repeat: no-repeat;
          color: $black;
          margin-bottom: 0;
          font-size: 17px;
        }
      }
    }

    .social_title {
      margin-bottom: 12px;
      font-size: 15px;
    }
  }

  .tf-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -26px;
    z-index: 10;
  }
}

.project-box-style2_wrapper {
  display: flex;

  @include res767 {
    flex-wrap: wrap;
  }
}

.project-box-style2 {
  width: calc(100% / 3);
  position: relative;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 15px;

  @include res767 {
    width: 100%;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $color-main1;
    right: -2.5px;
    bottom: -2.5px;
  }

  &:last-child {
    border-right: none;

    &::after {
      display: none;
    }
  }

  .image {
    margin-bottom: 24px;
    transform: rotateY(0);
    transition: all 0.8s ease;
  }

  .content {
    padding: 0 40px 62px;
    text-align: center;
    position: relative;
    overflow: hidden;

    h5 {
      margin-bottom: 13px;
    }

    .desc {
      margin-bottom: 10px;
    }

    .number {
      @include transition3;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 100px;
      color: $white;
      opacity: 0.06;
      font-weight: bold;
    }

    @include res991 {
      padding: 0 20px 62px;
    }
  }

  .btn_project {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15px;

    span {
      background-color: $color-hover;
      width: 30px;
      height: 30px;
      @include flex(center, center);
      border-radius: 50%;
      color: #000000;
    }
  }

  &:hover {
    .image {
      transform: rotateY(-360deg);
    }

    .number {
      opacity: 1;
    }
  }
}

.project-box-style4 {
  @include flex(flex-start, flex-start);

  .image {
    width: 112px;
    min-width: 112px;
    margin-right: 20px;
    overflow: hidden;
    position: relative;

    .img_inner {
      position: relative;
      background: url("./../../assets/images/common/project_56.png") center center no-repeat;
    }

    img {
      height: 112px;
      -webkit-mask-image: url("./../../assets/images/common/project_56.png");
      mask-image: url("./../../assets/images/common/project_56.png");
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      overflow: hidden;
    }

    .shape {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 2px;
      top: 2px;
    }
  }

  .label {
    font-size: 12px;
    line-height: 1.4;
    background-color: #798da3;
    @include flex(center, center);
    border-radius: 6px;
    width: 77px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    margin-top: 10px;
    font-weight: bold;
  }

  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 24px;
  background: #1e2835;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.0784313725);

  .content {
    text-align: left;

    h5 {
      font-size: 26px;
      margin-bottom: 4px;
    }

    p {
      font-size: 15px;
      margin-bottom: 16px;
    }

    ul {
      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        @include flex(flex-start, space-between);

        .text {
          @include flex(center, flex-start);
          padding: 8px 12px 8px 12px;
          height: 40px;
          width: 100%;
          max-width: 250px;
          background: url("./../../assets/images/common/bg_text_4.png");
          background-position: center right;
          background-repeat: no-repeat;
          margin-bottom: 0;
          font-size: 17px;
        }

        .price {
          @include flex(center, flex-end);
          padding: 8px 10px;
          height: 40px;
          max-width: 120px;
          width: 100%;
          // min-height: 120px;
          background: url("./../../assets/images/common/bg_text4.png");
          background-position: center left;
          background-repeat: no-repeat;
          color: $black;
          margin-bottom: 0;
          font-size: 17px;
        }
      }
    }
  }
}

.project-box-style5 {
  --tw-bg-opacity: 1;
  background: rgb(24 24 27 / var(--tw-bg-opacity));
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 24px;

  .boder {
    border-radius: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 1px solid;
    z-index: 9;
    --tw-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
    // --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    //   0 1px 2px -1px var(--tw-shadow-color);
    // --tw-ring-offset-shadow: 0 0 #0000;
    // --tw-ring-shadow: 0 0 #05040400;
    box-shadow: var(0 0 #0000, 0 0 #0000), var(0 0 #0000, 0 0 #0000),
      var(--tw-shadow);
    // box-shadow: 0 0 #0000, 0 0 #050404ff, 0 1px 3px 0 rgb(0 0 0 / 0.1),
    //   0 1px 2px -1px rgb(0 0 0 / 0.1);
    border-image-slice: 1;
    border-image-source: linear-gradient(rgba(255, 255, 255, 0.0784313725),
        rgba(255, 255, 255, 0));
  }

  &::after {
    width: 50px;
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    border: solid 1.7px rgba(255, 255, 255, 0.0784313725);
    border-color: rgba(255, 255, 255, 0.0784313725) transparent transparent transparent;
    border-radius: 50%/10px 10px 0 0;
    transform: rotate(40deg);
    top: 2px;
    right: 2px;
  }

  &::before {
    width: 50px;
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    border: solid 1.5px rgba(255, 255, 255, 0.0784313725);
    border-color: rgba(255, 255, 255, 0.0784313725) transparent transparent transparent;
    border-radius: 50%/10px 10px 0 0;
    transform: rotate(-40deg);
    left: 2px;
    top: 2px;
  }

  .img-box {
    @include flex(flex-start, flex-start);
    position: relative;
    z-index: 10;
    margin-bottom: 20px;
  }

  .image {
    position: relative;
    height: 112px;
    width: 112px;
    min-width: 112px;
    margin-right: 16px;
    background: url("./../../assets/images/common/project_1.png") center center no-repeat;

    img {
      &.mask {
        height: 112px;
        -webkit-mask-image: url("./../../assets/images/common/project_1.png");
        mask-image: url("./../../assets/images/common/project_1.png");
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
      }
    }

    .shape {
      position: absolute;
      right: 2px;
      top: 2px;
      z-index: -1;
      width: 30px;
      height: 30px;
    }
  }

  .content {
    position: relative;

    .heading {
      margin-bottom: 5px;
    }

    .title {
      margin-bottom: 6px;
    }

    .desc {
      margin-bottom: 0;
    }
  }

  .content-inner {
    position: relative;
    z-index: 10;

    .total {
      margin-bottom: 10px;

      h6 {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 0;
      }

      p {
        font-family: "resobot-bold";
        font-weight: 700;
        color: $color-hover;
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 0;
      }
    }

    ul {
      margin-bottom: 20px;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        @include flex(flex-start, space-between);

        .text {
          @include flex(center, flex-start);
          padding: 8px 12px 8px 16px;
          height: 40px;
          width: 100%;
          max-width: 320px;
          background: url("./../../assets/images/common/bg_text_2.png");
          background-position: center right;
          background-repeat: no-repeat;
          margin-bottom: 0;
          font-size: 17px;
        }

        .price {
          @include flex(center, flex-end);
          padding: 8px 10px;
          height: 40px;
          width: 180px;
          background: url("./../../assets/images/common/bg_text3.png");
          background-position: center left;
          background-repeat: no-repeat;
          color: $black;
          margin-bottom: 0;
          font-size: 17px;
        }
      }
    }
  }
}

.slider-2 {
  .swiper {
    padding: 0 30px 26px 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: -30px;
    margin-right: -30px;
  }
}

.counter_wrapper {
  .box {
    @include flex(center, flex-start);
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .image {
      @include flex(center, center);
      width: 100px;
      height: 110px;
      background: url("./../../assets/images/backgroup/bg_counter.png") center center no-repeat;
      background-size: contain;
      box-shadow: 0px 6px 10px 0px #00000024;
      margin-right: 26px;
    }

    .content {
      .desc {
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 4px;
      }

      .number-content {
        span {
          font-family: "resobot-bold";
          color: $color-main1;
          font-weight: bold;
          font-size: 40px;
          line-height: 44px;
        }
      }
    }
  }
}

.describe_chart {
  width: 25%;
  padding-top: 17px;

  li {
    margin-bottom: 30px;
    @include flex(center, flex-start);

    &:last-child {
      margin-bottom: 0;
    }

    .desc {
      margin-left: 14px;

      p {
        margin-bottom: 0;
      }

      .name {
        font-size: 14px;
        line-height: 19.6px;
      }

      .number {
        font-size: 15px;
        line-height: 21px;
        color: $white;
      }
    }
  }
}

.project-box-style3 {
  @include transition3;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .header_project {
    @include flex2;
    padding-left: 25px;

    .heading {
      font-size: 20px;
      line-height: 28px;
      margin-top: 8px;
    }

    .image {
      margin-right: 14px;
      position: relative;
      height: 90px;
      width: 90px;
      z-index: 9;
      background: url("./../../assets/images/common/project_8.png") center center no-repeat;

      img {
        &.mask {
          height: 90px;
          -webkit-mask-image: url("./../../assets/images/common/project_8.png");
          mask-image: url("./../../assets/images/common/project_8.png");
          -webkit-mask-position: center center;
          mask-position: center center;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
        }
      }

      .shape {
        position: absolute;
        right: 2px;
        top: 2px;
        z-index: -1;
        width: 24px;
        height: 24px;
      }
    }
  }

  .content {
    margin-top: -45px;
    background: #1e2835;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.0784313725);
    padding: 20px 20px 17px 129px;
    @include flex(center, flex-start);

    .td1 {
      width: 15%;
    }

    .td2 {
      width: 15%;
      padding-left: 10px;
    }

    .td3 {
      padding-left: 38px;
      width: 10%;
    }

    .td4 {
      padding-left: 68px;
      width: 30%;
    }

    .td5 {
      width: 30%;
      @include flex(center, flex-end);

      ul {
        @include flex(center, flex-end);
      }
    }

    .td {
      p {
        line-height: 1.4;
        margin-bottom: 0;

        &:nth-child(1) {
          font-size: 14px;
          margin-bottom: 2px;
        }

        &:nth-child(2) {
          font-size: 17px;
          color: $white;
        }
      }
    }
  }

  &:hover {
    transform: translateY(-10px);
  }
}

.social {
  @include flex(center, center);

  &.justify-content-start {
    justify-content: flex-start;
  }

  li {
    margin: 0 4px;

    a {
      @include flex(center, center);
      width: 44px;
      height: 44px;
      border: 1px solid #798da3;
      border-radius: 50%;

      svg {
        path {
          @include transition3;
        }
      }

      &:hover {
        background-color: $color-main1;
        border-color: $color-main1;

        svg {
          path {
            fill: #09121d;
          }
        }
      }
    }
  }
}

.team-box-wrapper {
  @include flex2;
  flex-wrap: wrap;
  justify-content: space-between;
}

.team-box {
  width: calc((100% - 90px) / 4);
  position: relative;
  margin-bottom: 20px;

  .shape {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 4px;
    z-index: 9;
    margin-left: 106px;
    opacity: 1;
    visibility: visible;
    @include transition3;
  }

  .shape_ecfect {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 4px;
    z-index: 9;
    margin-left: 106px;
    opacity: 0;
    visibility: hidden;
    @include transition3;
  }

  .image {
    z-index: 999;
    background: url("./../../assets/images/backgroup/bg_img_team.png") center center no-repeat;
    width: 156px;
    height: 176px;
    margin: 0 auto;
    position: relative;

    img {
      width: 156px;
      height: 176px;
      -webkit-mask-image: url("./../../assets/images/backgroup/bg_img_team.png");
      mask-image: url("./../../assets/images/backgroup/bg_img_team.png");
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      overflow: hidden;
    }
  }

  .content {
    width: 100%;
    text-align: center;
    padding: 112px 20px 42px;
    margin-top: -102px;
    border-radius: 20px;
    position: relative;
    -webkit-mask-image: url("./../../assets/images/backgroup/bg_team.png");
    mask-image: url("./../../assets/images/backgroup/bg_team.png");
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    background: url("./../../assets/images/backgroup/bg_team.png");
    background-position: center, center;
    background-size: cover;
    z-index: 99;

    .name {
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 0;
    }

    .position {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 20px;
    }
  }

  &:hover {
    .shape {
      opacity: 0;
      visibility: hidden;
    }

    .shape_ecfect {
      opacity: 1;
      visibility: visible;
    }
  }

  @include res991 {
    width: calc((100% - 60px) / 3);
  }

  @include res767 {
    width: calc((100% - 60px) / 2);
  }

  @include res600 {
    width: 100%;
  }
}

.partner-wrapper {
  @include flex(center, space-between);
  flex-wrap: wrap;

  .image {
    width: calc((100% - 150px) / 6);
    margin-bottom: 30px;

    img {
      opacity: 0.3;
      @include transition3;

      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }

  &.style3 {
    @include flex(center, center);
    margin-left: -30px;
    flex-wrap: wrap;

    .image {
      width: calc(16.6666666667% - 30px);
      margin-left: 30px;
      height: 70px;
      position: relative;
      -webkit-mask-image: url("./../../assets/images/backgroup/bg_partner_2.png");
      mask-image: url("./../../assets/images/backgroup/bg_partner_2.png");
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      background: url("./../../assets/images/backgroup/bg_partner_2.png");
      background-position: center, center;
      background-size: cover;
      @include flex(center, center);
    }
  }
}

.move4 {
  -webkit-animation: move4 5s infinite linear;
  animation: move4 5s infinite linear;
  z-index: 2;
}

.icon-box {
  position: relative;
  @include transition3;
  max-width: 350px;
  margin: 0 auto;

  .top {
    text-align: center;
    -webkit-mask-image: url("./../../assets/images/backgroup/bg_box_1.png");
    mask-image: url("./../../assets/images/backgroup/bg_box_1.png");
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background: url("./../../assets/images/backgroup/bg_box_1.png");
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    max-height: 352px;
    height: 100%;
    padding: 58px 30px 33px;
    width: 100%;
    position: relative;
    z-index: 1;

    .icon {
      margin-bottom: 12px;

      img {
        transform: scale(1);
        @include transition3;
      }
    }

    &::after {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.0784313725);
      height: 100%;
      width: 2px;
      display: none;
    }

    h5 {
      margin-bottom: 13px;
    }

    p {
      font-family: "Space Grotesk", sans-serif;
      font-size: 15px;
      line-height: 21px;
      font-weight: 500;
      color: #798da3;
    }
  }

  .bottom {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    text-align: center;
  }

  .number {
    position: absolute;
    bottom: 0;
    font-family: "resobot-bold";
    font-size: 26px;
    line-height: 1.4;
    color: $white;
    @include flex(center, center);
    width: 46px;
    height: 41px;
    background: url("./../../assets/images/backgroup/bg_num_box1.png") center center no-repeat;
    background-size: contain;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    bottom: -20px;
  }

  &:hover {
    transform: translateY(-10px);

    .top {
      .icon {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.image-box {
  position: relative;
  background-color: $ourColor_cardbg;
  border-radius: 20px;
  padding: 70px 14px 38px;
  border: 2px solid $ourColor_border;
  text-align: center;

  .number {
    position: absolute;
    top: -5px;
    width: 100%;
    left: 0;
    @include flex(center, center);
    padding: 0 25px;

    img {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    h6 {
      color: $white;
      font-size: 20px;
      position: absolute;
      margin-bottom: 0;
    }
  }

  .content {
    .name {
      font-size: 20px;
      color: $color-hover;
      margin-bottom: 17px;
    }

    ul {
      li {
        padding: 10px;
        margin-bottom: 6px;
        border-radius: 6px;

        &.bg_style {
          background-color: #171f29;
        }

        &:last-child {
          margin-bottom: 0;
        }

        p {
          margin-bottom: 0;

          &.price {
            color: $color-hover;
            font-size: 17px;
            line-height: 1.4;
          }
        }
      }
    }
  }

  .wrapper_button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    width: 100%;
    @include flex(center, center);
  }
}

.roadmap_boder {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 500px;
    height: 2px;
    top: 16px;
  }

  &::before {
    left: -300px;
    background-color: $color-hover;
    opacity: 1;
  }

  &::after {
    right: -300px;
    background-color: #141e2a;
    z-index: -1;
  }
}

.roadmap-box {
  position: relative;
  padding-top: 71px;

  .date {
    font-size: 17px;
    font-family: $font-body;
  }

  ul {
    li {
      padding-right: 10px;
      position: relative;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      &::after {
        position: absolute;
        content: "";
        width: 4px;
        height: 4px;
        background-color: #798da3;
        border-radius: 50%;
        right: 0;
        top: 10px;
      }
    }
  }

  &.active {
    .date {
      color: $color-hover;
    }

    &::before {
      background-color: $color-hover;
      opacity: 1;
    }

    .icon {
      background-color: $color-hover;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 16px;
    width: calc(100% - -30px);
    height: 2px;
    background-color: #141e2a;
    left: -30px;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 71px;
    background-color: rgba(255, 255, 255, 0.1);
    right: 20px;
    top: 0;
    z-index: 1;
  }

  .icon {
    @include flex(center, center);
    position: absolute;
    min-width: 36px;
    min-height: 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #798da3;
    right: 2px;
    top: 0;
    z-index: 2;
  }

  .content {
    text-align: right;
    padding: 8px 17px 14px 20px;
    background: linear-gradient(90deg,
        rgba(35, 44, 54, 0) -0.24%,
        #1a222c 100%);
    border-radius: 20px;
    border: 2px solid #2c333c;

    .date {
      margin-bottom: 4px;
    }
  }
}

.project-box-style6 {
  position: relative;
  overflow: hidden;

  .image {
    overflow: hidden;
    position: relative;
    border-radius: 20px 20px 0 0;

    img {
      border-radius: 20px 20px 0 0;
    }
  }

  .content {
    background: #1e2835;
    overflow: hidden;
    border-radius: 0 0 20px 20px;
    padding: 20px 18px;
    position: relative;
    border: 2px solid rgba(255, 255, 255, 0.0784313725);
    border-top: 0;

    .img-box {
      @include flex(flex-start, flex-start);
      position: relative;
      z-index: 10;
      margin-bottom: 24px;
      padding-bottom: 20px;

      &::after {
        position: absolute;
        content: "";
        height: 1px;
        width: 100%;
        background: #798da3;
        opacity: 0.12;
        bottom: 0;
        left: 0;
      }

      .image_inner {
        position: relative;
        height: 90px;
        width: 90px;
        min-width: 90px;
        margin-right: 20px;
        background: url("./../../assets/images/common/project_24.png") center center no-repeat;

        img {
          &.mask {
            height: 90px;
            -webkit-mask-image: url("./../../assets/images/common/project_24.png");
            mask-image: url("./../../assets/images/common/project_24.png");
            -webkit-mask-position: center center;
            mask-position: center center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
          }
        }

        .shape {
          position: absolute;
          right: 2px;
          top: 2px;
          z-index: -1;
          width: 24px;
          height: 24px;
        }
      }
    }

    .heading {
      margin-bottom: 5px;
      font-size: 28px;
    }

    .title {
      margin-bottom: 8px;
    }

    .desc {
      margin-bottom: 0;
    }
  }

  .content-bottom {
    position: relative;
    z-index: 10;

    .total {
      margin-bottom: 10px;

      h6 {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 0;
      }

      p {
        font-family: "resobot-bold";
        font-weight: 700;
        color: $color-hover;
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 0;
      }
    }

    .sub-content {
      @include flex(center, center);
      margin: 18px 0 24px;

      li {
        position: relative;
        text-align: center;
        width: 33.3333%;

        &:nth-child(1) {
          padding-right: 15px;
        }

        &:nth-child(3) {
          padding-left: 15px;
        }

        &::after {
          position: absolute;
          content: "";
          height: 100%;
          width: 1px;
          background: #798da3;
          opacity: 0.12;
          top: 0;
          right: 0;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        p {
          margin-bottom: 0;
          line-height: 1.4;

          &:nth-child(1) {
            font-size: 14px;
          }

          &:nth-child(2) {
            color: $white;
          }
        }
      }
    }
  }
}

.project-box-style7 {
  position: relative;
  overflow: hidden;
  @include flex(center, flex-start);

  .image {
    overflow: hidden;
    position: relative;
    min-width: 243px;
    border-radius: 20px 0 0 20px;

    img {
      height: 252px;
      object-fit: cover;
      width: 243px;
    }
  }

  .content {
    height: 100%;
    width: 100%;
    background: #1e2835;
    overflow: hidden;
    border-radius: 0 20px 20px 0;
    padding: 14px 16px;
    position: relative;
    border: 2px solid rgba(255, 255, 255, 0.0784313725);
    border-left: 0;

    .heading {
      margin-bottom: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }

    ul {
      margin-bottom: 20px;

      li {
        @include flex(center, flex-start);
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 8px;

        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border: none;
        }

        p {
          font-size: 17px;
          line-height: 1.4;
          font-weight: bold;
          margin-bottom: 0;

          &:nth-child(1) {
            width: 58%;
          }

          &:nth-child(2) {
            width: 42%;
            color: $color-hover;
          }
        }
      }
    }

    .title {
      margin-bottom: 6px;
    }

    .desc {
      margin-bottom: 0;
    }
  }

  .content-progress-box.style2 .progress-title {
    margin-bottom: 4px;
  }

  .content-progress-box.style2 .progress-title-holder span {
    @include flex(center, space-between);
    width: 100%;

    .progress-number-mark {
      @include flex(center, flex-end);
      padding-right: 35px;

      span {
        width: auto;

        &.percent {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .bg_style {
    position: absolute;
    right: 0;
    top: 0;

    .image_inner {
      position: relative;
      @include flex(center, center);

      .icon {
        @include flex(center, center);
        width: 24px;
        height: 24px;
        background: #414b57;
        border-radius: 50%;
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }
}

.icon-box-style2 {
  padding: 10px 18px 13px;
  text-align: left;
  background: linear-gradient(90deg, #1a222c -0.24%, rgba(35, 44, 54, 0) 100%);
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.0784313725);

  .icon {
    width: 54px;
    height: 54px;
    margin-bottom: 12px;
  }

  .content {
    .title {
      margin-bottom: 6px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.features {
  .wrapper-box {
    @include flex(center, center);
    flex-wrap: wrap;
    margin-left: -30px;

    .icon-box-style2 {
      margin-bottom: 30px;
      width: calc(33.3333% - 30px);
      margin-left: 30px;
    }
  }
}

.icon-box-style3 {
  @include flex(center, center);
  margin-bottom: 28px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .icon {
    position: relative;
    margin-right: 30px;
    width: 125px;
    min-width: 125px;
    height: 140px;
    -webkit-mask-image: url("./../../assets/images/backgroup/bg_icon_box.png");
    mask-image: url("./../../assets/images/backgroup/bg_icon_box.png");
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    overflow: hidden;
    @include flex(center, center);
    background: url("./../../assets/images/backgroup/bg_icon_box.png") center center no-repeat;
    background-size: contain;

    img {
      transform: scale(1);
      @include transition3;
      width: 54px;
      height: 54px;
    }
  }

  .icon_bottom {
    position: absolute;
    bottom: -32px;
    left: 0;
    z-index: -1;
  }

  .content {
    .title {
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 0;
    }
  }

  &:hover {
    .icon {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.icon-box-style4 {
  border: 2px solid rgba(255, 255, 255, 0.0784313725);
  text-align: center;
  position: relative;
  background: $bg-3;
  border-radius: 20px;
  padding: 15px 20px 12px;

  .icon-wrapper {
    @include flex(center, center);
    position: relative;
    margin-right: 30px;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;

    svg {
      path {
        @include transition3;
        fill: $txt-color;
      }
    }
  }

  h6 {
    font-size: 16px;
    @include transition3;
    margin-bottom: 0;
  }

  &:hover {
    .icon-wrapper {
      svg {
        path {
          fill: $color-hover;
        }
      }
    }

    h6 {
      color: $color-hover;
    }
  }
}

.wrapper-menutab {
  display: block;
}

.project-box-style6_wrapper {
  @include flex2;
  // margin-left: -30px;
  flex-wrap: wrap;

  .project-box-style4,
  .project-box-style6 {
    margin-bottom: 30px;
  }
}

.project-box-wrapper {
  @include flex2;
  margin-left: -30px;
  flex-wrap: wrap;

  .project-box {
    width: calc(33.333% - 30px);
    margin-left: 30px;
    margin-bottom: 68px;
  }
}

.project_wrapper_1 {
  @include flex2;
  margin-left: -30px;
  flex-wrap: wrap;

  .project-box-style4 {
    width: calc(50% - 30px);
    margin-left: 30px;
    margin-bottom: 30px;
  }
}

.project_wrapper_2 {
  @include flex2;
  margin-left: -30px;
  flex-wrap: wrap;

  .project-box-style7 {
    display: block;
    width: calc(33.333% - 30px);
    margin-left: 30px;
    margin-bottom: 30px;

    .content {
      padding: 16px 16px 13px;
      border: none;
      position: relative;
      border-radius: 0 0;
      overflow: hidden;
      height: auto;
      background: transparent;

      ul li p:nth-child(1) {
        width: 48%;
      }

      .heading {
        width: 100%;
        text-overflow: initial;
      }

      &::after {
        z-index: -2;
        border-radius: 0 0 20px 20px;
        background: #1e2835;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
      }

      &::before {
        z-index: -1;
        border-radius: 0 0 20px 20px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 2px solid rgba(255, 255, 255, 0.0784313725);
        content: "";
      }
    }

    .image {
      img {
        width: 100%;
        height: auto;
      }

      border-radius: 20px 20px 0 0;
    }
  }

  .project-box-style7 .content-progress-box.style2 .progress-title-holder span .progress-number-mark {
    padding-right: 0;

    span {
      color: $white;

      &.num {
        color: $color-hover;
        margin-left: 4px;
      }

      &.percent {
        position: absolute;
        bottom: -38px;
        left: 0;
      }
    }
  }

  .text_bottom {
    text-align: right;
    width: 100%;
    margin-bottom: 0;
    margin-top: 2px;

    span {
      font-size: 12px;
      color: $white;
    }
  }
}

.project_detail.project-box-style5 {
  padding: 20px 20px 8px;

  .content-progress-box {
    .progress-bar {
      margin-bottom: 0;
    }

    p {
      font-size: 14px;

      &.mb-1 {
        margin-bottom: -1px !important;
      }

      &.flex {
        justify-content: space-between;
      }
    }
  }

  .img-box {
    margin-bottom: 20px;
  }

  .image {
    margin-right: 14px;
    position: relative;
    height: 90px;
    width: 90px;
    min-width: 90px;
    background: url("./../../assets/images/common/project_24.png") center center no-repeat;

    img {
      &.mask {
        height: 90px;
        -webkit-mask-image: url("./../../assets/images/common/project_24.png");
        mask-image: url("./../../assets/images/common/project_24.png");
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
      }
    }

    .shape {
      width: 24px;
      height: 24px;
    }
  }

  .content {
    .heading {
      font-size: 28px;
      margin-bottom: 2px;
    }

    .desc {
      font-size: 17px;
      color: $white;
    }
  }

  .content-inner {
    .sub {
      margin-bottom: 8px;
    }

    .kyc {
      @include flex(center, flex-start);
      border: 2px solid rgba(255, 255, 255, 0.0784313725);
      background: $bg-2;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 20px;

      .icon {
        margin-right: 10px;
      }

      p {
        margin-bottom: 0;
        color: $white;
      }
    }

    .boder-st {
      position: relative;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.1);
      margin: 20px 0;

      &.mt0 {
        margin-top: 0;
      }
    }

    .total {
      p {
        font-family: $font-body;
        font-size: 15px;
        font-weight: 500;
        color: #798da3;

        &.active {
          font-size: 17px;
          color: $white;
          font-weight: bold;
        }
      }
    }
  }

  .content-progress-box.style2 {
    .progress-title-holder span {
      font-size: 14px;
    }

    .progress-content-outter {
      margin-bottom: 6px;
    }

    .flex {
      @include flex(center, space-between);
      font-size: 14px;
      color: $white;
    }
  }
}

.team-box-style2 {
  @include flex2;
  padding: 21.5px 18px 18.5px 20px;
  background-color: $bg-2;
  border-radius: 20px;
  margin-bottom: 10px;
  border: 2px solid rgba(255, 255, 255, 0.0784313725);

  &:last-child {
    margin-bottom: 0;
  }

  .image {
    z-index: 99;
    width: 102px;
    min-width: 102px;
    height: 116px;
    position: relative;
    overflow: hidden;
    margin-right: 30px;
    background: url("./../../assets/images/backgroup/bg_img_team_2.png") center center no-repeat;

    img {
      z-index: 999;
      width: 102px;
      height: 116px;
      -webkit-mask-image: url("./../../assets/images/backgroup/bg_img_team_2.png");
      mask-image: url("./../../assets/images/backgroup/bg_img_team_2.png");
      -webkit-mask-position: center center;
      mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      overflow: hidden;
    }
  }

  .content {
    p {
      margin-bottom: 0 !important;

      &.position {
        font-size: 14px;
        margin-bottom: 8px !important;
      }
    }

    .name {
      font-size: 20px;
      margin-top: -4px;
      margin-bottom: -4px;
    }
  }
}

.roadmap-wrapper-style2 {
  position: relative;
  width: 100%;
  @include flex(flex-start, flex-start);
  overflow: hidden;

  .right,
  .left {
    width: 50%;
  }

  .right {
    padding-top: 193px;

    .roadmap-box-style {
      margin-bottom: 195px;

      .content {
        text-align: left;
        margin-left: auto;

        &::after {
          left: -6px;
        }

        &::before {
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        .date {
          margin-bottom: 3px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      ul {
        li {
          padding-right: 0;
          padding-left: 10px;

          &::after {
            left: 0;
          }
        }
      }

      &::before {
        display: none;
      }

      &::after {
        left: 0;
      }

      .icon {
        left: -18px;
      }
    }
  }

  .roadmap-box-style {
    position: relative;
    margin-bottom: 187px;

    &:last-child {
      margin-bottom: 0;
    }

    .date {
      font-size: 17px;
    }

    ul {
      li {
        padding-right: 10px;
        position: relative;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        &::after {
          position: absolute;
          content: "";
          width: 4px;
          height: 4px;
          background-color: #798da3;
          border-radius: 50%;
          right: 0;
          top: 10px;
        }
      }
    }

    &.active {
      .date {
        color: $color-hover;
      }

      &::before {
        background-color: $color-hover;
        opacity: 1;
      }

      .icon {
        background-color: $color-hover;
      }

      .content {
        &::after {
          background-color: $color-hover;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: -187px;
      width: 2px;
      height: calc(100% + 187px);
      background-color: #141e2a;
      right: 0;
      z-index: -99;
    }

    &::after {
      content: "";
      position: absolute;
      width: 180px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.1);
      right: 0;
      top: 50%;
      z-index: -2;
      transform: translateY(-50%);
    }

    .icon {
      @include flex(center, center);
      position: absolute;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #798da3;
      right: -18px;
      top: 50%;
      z-index: 999;
      transform: translateY(-50%);
    }

    .content {
      text-align: right;
      padding: 10px 30px;
      border-radius: 20px;
      border: 2px solid #2c333c;
      max-width: 380px;
      position: relative;

      &::after {
        position: absolute;
        width: 10px;
        height: 10px;
        content: "";
        background-color: #798da3;
        border-radius: 50%;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: linear-gradient(90deg,
            rgba(35, 44, 54, 0) -0.24%,
            #1a222c 100%);
        border-radius: 20px;
        z-index: -1;
      }

      .date {
        margin-bottom: 10px;
        font-family: $font-body;
      }
    }
  }
}

.grid-box {
  margin-bottom: 65px;
  position: relative;

  .image {
    overflow: hidden;
    border-radius: 20px 20px 0 0;

    img {
      transform: scale(1);
      @include transition3;
      width: 100%;
    }
  }

  .content {
    padding: 14px 14px 45px;
    border-radius: 0 0 20px 20px;
    background-color: $bg-3;
    border: 2px solid rgba(255, 255, 255, 0.0784313725);
    border-top: 0;

    .tag {
      color: $color-hover;
      font-size: 12px;
      font-weight: bold;
      font-family: $font-main;
    }

    .title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .tf-button {
    position: absolute;
    left: 16px;
    bottom: -26px;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.grid-box-style2 {
  position: relative;
  @include flex2;
  margin-bottom: 54px;
  @include transition3;
  transform: translate(0);

  &:hover {
    transform: translateY(-10px);

    .image {
      img {
        transform: scale(1.1);
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .image {
    overflow: hidden;
    width: 42.5%;
    border-radius: 20px 0 0 20px;

    img {
      @include transition3;
      transform: scale(1);
      height: 100%;
    }
  }

  .content {
    width: 57.5%;
    padding: 13px 15px 44px;
    border-radius: 0 20px 20px 0;
    background-color: $bg-3;
    border: 2px solid rgba(255, 255, 255, 0.0784313725);
    border-left: 0;

    .tag {
      color: $color-hover;
      font-size: 12px;
      font-weight: bold;
      font-family: $font-main;
    }

    .title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .tf-button {
    position: absolute;
    right: 17px;
    bottom: -25px;
  }
}

#container {
  SVG {
    path {
      stroke: transparent;
    }
  }
}

.c-projectimage {
  height: 100px;
}